import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";

function CarouselComponentSlickStyles(props) {
  return (
    <></>
  );
}
export default CarouselComponentSlickStyles;
